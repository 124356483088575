import { graphql, useStaticQuery } from "gatsby"

export const useCars = () => {
  const {
    fiat500,
    hyundaii10,
    toyotaaygo,
    fabia,
    yaris,
    hyundaii20,
    focus,
    hyundaii30,
    golf6,
    a4,
    mondeo,
    optima,
    hyundaii40,
    a6,
    bmw520,
    avensis,
    arteon,
    a8,
    bmw7,
    mercedesklasae,
    mustang,
    camaro,
    golf7,
    m4,
    mercedescls,
    daf,
    ciagnikman,
    ciezarowywinda,
    dostawczy,
    dostawczywinda,
    ciezarowy,
    ducatoskrzynia,
    ford9os,
    naczepa,
    plandeka,
    taxi1,
    taxi2,
    naukajazdy2,
    naukajazdy,
  } = useStaticQuery(graphql`
    query {
      fiat500: file(relativePath: { eq: "flota/fiat500.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hyundaii10: file(relativePath: { eq: "flota/hyundaii10.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      toyotaaygo: file(relativePath: { eq: "flota/toyota-aygo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fabia: file(relativePath: { eq: "flota/skoda-fabia.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      yaris: file(relativePath: { eq: "flota/toyota-yaris.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hyundaii20: file(relativePath: { eq: "flota/hyundai-i20.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      focus: file(relativePath: { eq: "flota/ford_focus.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hyundaii30: file(relativePath: { eq: "flota/hyundai-i30.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      golf6: file(relativePath: { eq: "flota/volkswagen-golf-6.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      a4: file(relativePath: { eq: "flota/audi_a4.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mondeo: file(relativePath: { eq: "flota/ford_mondeo.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      optima: file(relativePath: { eq: "flota/kia_optima.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      hyundaii40: file(relativePath: { eq: "flota/hyundai_i40.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      a6: file(relativePath: { eq: "flota/audi-a6.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bmw520: file(relativePath: { eq: "flota/bmw520.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      avensis: file(relativePath: { eq: "flota/toyota-avensis.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      arteon: file(relativePath: { eq: "flota/arteon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      a8: file(relativePath: { eq: "flota/audi-a8.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bmw7: file(relativePath: { eq: "flota/BMW7.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mercedesklasae: file(relativePath: { eq: "flota/Mercedes-klasy-E.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mustang: file(relativePath: { eq: "flota/mustang.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      camaro: file(relativePath: { eq: "flota/camaro.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      golf7: file(relativePath: { eq: "flota/golf7.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      m4: file(relativePath: { eq: "flota/m4.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      mercedescls: file(relativePath: { eq: "flota/MercedesAMGCLS.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ciagnikman: file(relativePath: { eq: "flota/ciagnikman.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      daf: file(relativePath: { eq: "flota/daf.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ciezarowywinda: file(relativePath: { eq: "flota/ciezarowywinda.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      dostawczy: file(relativePath: { eq: "flota/dostawczy.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      dostawczywinda: file(relativePath: { eq: "flota/dostawczywinda.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ciezarowy: file(relativePath: { eq: "flota/ciezarowy.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ducatoskrzynia: file(relativePath: { eq: "flota/ducatoskrzynia.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ford9os: file(relativePath: { eq: "flota/ford9os.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      naczepa: file(relativePath: { eq: "flota/naczepa.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      plandeka: file(relativePath: { eq: "flota/plandeka.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      taxi1: file(relativePath: { eq: "flota/taxi1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      taxi2: file(relativePath: { eq: "flota/taxi2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      naukajazdy: file(relativePath: { eq: "flota/naukajazdy.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      naukajazdy2: file(relativePath: { eq: "flota/naukajazdy2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const cars = {
    segmentA: [
      {
        name: "Toyota Aygo",
        img: toyotaaygo,
      },
      {
        name: "Fiat 500",
        img: fiat500,
      },
      {
        name: "Hyundai i10",
        img: hyundaii10,
      },
    ],
    segmentB: [
      {
        name: "Skoda Fabia",
        img: fabia,
      },
      {
        name: "Toyota Yaris",
        img: yaris,
      },
      {
        name: "Hyundai i20",
        img: hyundaii20,
      },
    ],
    segmentC: [
      {
        name: "Hyundai I30",
        img: hyundaii30,
      },
      {
        name: "Volkswagen Golf VI",
        img: golf6,
      },
      {
        name: "Ford Focus",
        img: focus,
      },
      {
        name: "Volkswagen Golf VII",
        img: golf7,
      },
    ],
    segmentD: [
      {
        name: "Hyundai I40",
        img: hyundaii40,
      },
      {
        name: "Audi A4",
        img: a4,
      },
      {
        name: "Kia Optima",
        img: optima,
      },
      {
        name: "Ford Mondeo",
        img: mondeo,
      },
    ],
    segmentE: [
      {
        name: "Volkswagen Arteon",
        img: arteon,
      },
      {
        name: "Audi A6",
        img: a6,
      },
      {
        name: "BMW 520",
        img: bmw520,
      },
      {
        name: "Toyota Avensis",
        img: avensis,
      },
    ],
    segmentF: [
      {
        name: "Audi A8",
        img: a8,
      },
      {
        name: "BMW 7",
        img: bmw7,
      },
      {
        name: "Mercedes Klasa E",
        img: mercedesklasae,
      },
      {
        name: "Mercedes CLS",
        img: mercedescls,
      },
    ],
    segmentG: [
      {
        name: "Ford Mustang",
        img: mustang,
      },
      {
        name: "Chevrolet Camaro",
        img: camaro,
      },
      {
        name: "BMW M4",
        img: m4,
      },
    ],
    dostawcze: [
      {
        name: "Pojazd dostawczy",
        img: dostawczy,
      },
      {
        name: "Pojazd dostawczy (winda)",
        img: dostawczywinda,
      },

      {
        name: "Pojazd dostawczy (plandeka)",
        img: plandeka,
      },
      {
        name: "Fiat Ducato (skrzynia)",
        img: ducatoskrzynia,
      },
      {
        name: "Ford (9 osobowy)",
        img: ford9os,
      },
    ],
    ciezarowe: [
      {
        name: "Ciągnik MAN",
        img: ciagnikman,
      },
      {
        name: "DAF",
        img: daf,
      },
      {
        name: "Pojazd ciężarowy (winda)",
        img: ciezarowywinda,
      },
      {
        name: "Pojazd ciężarowy",
        img: ciezarowy,
      },
      {
        name: "Naczepa",
        img: naczepa,
      },
    ],
    specjalne: [
      {
        name: "Taxi",
        img: taxi1,
      },
      {
        name: "Taxi",
        img: taxi2,
      },
      {
        name: "Pojazd do nauki jazdy",
        img: naukajazdy,
      },
      {
        name: "Pojazd do nauki jazdy",
        img: naukajazdy2,
      },
    ],
  }

  return { cars }
}
