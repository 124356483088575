import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import CarCard, { StyledCard } from "../CarCard"
import Title from "../Title"

const StyledSection = styled.section`
  padding: ${props => (props.contactBelow ? "32px 16px 64px" : "32px 16px")};
  max-width: 1200px;
  margin: 0 auto;
  @media (min-width: 768px) {
    padding: 40px 16px;
    max-width: 576px;
  }
  @media (min-width: 1024px) {
    max-width: 768px;
    &:last-child {
      padding: 40px 16px 80px;
    }
  }
  @media (min-width: 1200px) {
    max-width: 1200px;
  }
`

const Cars = styled.div`
  margin: 24px 0 0;
  .slick-slide {
    margin-right: 16px;
    opacity: 0.5;
    transition: opacity 0.3s ease;

    &.slick-active {
      opacity: 1;
    }
  }
  ${StyledCard} {
    width: 242px;
  }
  @media (min-width: 768px) {
    .slick-slide {
      opacity: 1;
    }
    .slick-list {
      padding: 20px;
      width: 100%;
      transform: translateX(-20px);
    }
  }
  @media (min-width: 1024px) {
    .slick-slide {
      margin-right: 30px;
    }
  }
  @media (min-width: 1200px) {
    ${StyledCard} {
      width: 270px;
      height: 340px;
    }
  }
`

const Section = ({ title, cars, contactBelow }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,
  }
  return (
    <StyledSection contactBelow={contactBelow}>
      <Title>
        <span>{title}</span>
      </Title>
      <Cars>
        <Slider {...settings}>
          {cars.map(car => (
            <CarCard key={car.name} {...car} />
          ))}
        </Slider>
      </Cars>
    </StyledSection>
  )
}

export default Section
