import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Layout from "../components/layout/Layout"
import Section from "../components/flota/Section"
import { useCars } from "../hooks/cars"
import { StyledButton } from "../components/styledButton"
import Contact from "../components/Contact"
import { Pagination, PaginationItem, PaginationLink } from "reactstrap"

const Header = styled.header`
  margin-top: 120px;
  padding: 0 16px;
  & h1 {
    font-size: 30px;
    line-height: 42px;
    font-weight: 900;
    letter-spacing: 1px;
    color: #062964;
    width: 100%;
    margin-bottom: 16px;
  }
  @media (min-width: 768px) {
    max-width: 576px;
    margin: 120px auto 0;
    & h1 {
      max-width: 576px;
    }
  }
  @media (min-width: 1024px) {
    display: flex;
    max-width: 1200px;
    margin: 220px auto 80px;
    & h1 {
      font-size: 48px;
      margin-bottom: 32px;
      line-height: normal;
    }
  }
`

const Paragraph = styled.p`
  color: #3a465b;
  line-height: 25px;
  letter-spacing: 0.1px;
  margin-bottom: 32px;
  position: relative;
  @media (min-width: 1024px) {
    width: 75%;
    padding-left: 30px;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.3px;
    margin-bottom: 34px;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      height: 100%;
      background-color: #fff;
      border-radius: 1px;
    }
  }
`

const ExtendStyledButton = styled(StyledButton)`
  width: -webkit-fill-available;
  text-align: center;
  @media (min-width: 1024px) {
    width: auto;
    padding: 13px 46px;
  }
`

const StyledImg = styled(Img)`
  display: none;
  @media (min-width: 1024px) {
    display: block;
    width: 100%;
    max-height: 400px;
  }
`

const PaginationWrapper = styled.div`
  width: auto;
  border-bottom: 1px solid #c7dbf5;
  margin: 24px 16px 0;
  overflow-x: auto;
  & .pagination {
    display: flex;
    margin: 0 auto;
    & .page-link {
      padding: 10px;
      color: #3a465b;
      font-size: 14px;
      letter-spacing: 0.3px;
      text-decoration: none;
      min-width: max-content;
    }
    & .page-item.active {
      & .page-link {
        border-bottom: 2px solid #196fd9;
        font-weight: 700;
      }
    }
  }
  @media (min-width: 768px) {
    margin: 24px 0 0;
    & .pagination {
      max-width: 576px;
      & .page-link {
        padding: 20px;
        font-size: 20px;
      }
    }
  }
  @media (min-width: 1024px) {
    & .pagination {
      max-width: 768px;
    }
  }
  @media (min-width: 1200px) {
    & .pagination {
      max-width: 1200px;
    }
  }
`

const Flota = () => {
  const [currentPage, setCurrentPage] = useState("Osobowe i luksusowe")
  const { flotabg } = useStaticQuery(graphql`
    query {
      flotabg: file(relativePath: { eq: "flotabg.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const { cars } = useCars()
  const pages = ["Osobowe i luksusowe", "Dostawcze", "Ciężarowe", "Specjalne"]
  return (
    <Layout>
      <Header>
        <div>
          <h1>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h1>
          <Paragraph>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellat ex
            cupiditate qui vitae animi odit rem aut atque architecto porro amet,
            dolorem a dicta perferendis illum maiores voluptatum perspiciatis
            harum?
          </Paragraph>
          <ExtendStyledButton as={AnchorLink} to="/flota#kontakt">
            Kontakt
          </ExtendStyledButton>
        </div>
        <StyledImg fluid={flotabg.childImageSharp.fluid} />
      </Header>
      <PaginationWrapper>
        <Pagination>
          {pages.map(page => (
            <PaginationItem key={page} active={currentPage === page}>
              <PaginationLink
                href="#"
                value={page}
                onClick={e => {
                  e.preventDefault()
                  setCurrentPage(page)
                }}
              >
                {page}
              </PaginationLink>
            </PaginationItem>
          ))}
        </Pagination>
      </PaginationWrapper>
      {currentPage === "Osobowe i luksusowe" && (
        <>
          <Section title="Segment A" cars={cars.segmentA} />
          <Section title="Segment B" cars={cars.segmentB} />
          <Section title="Segment C" cars={cars.segmentC} />
          <Section title="Segment D" cars={cars.segmentD} />
          <Section title="Segment E" cars={cars.segmentE} />
          <Section title="Segment F" cars={cars.segmentF} />
          <Section title="Segment G" cars={cars.segmentG} />
        </>
      )}
      {currentPage === "Dostawcze" && (
        <Section title="Pojazdy dostawcze" cars={cars.dostawcze} />
      )}
      {currentPage === "Ciężarowe" && (
        <Section title="Pojazdy ciężarowe" cars={cars.ciezarowe} contactBelow />
      )}
      {currentPage === "Specjalne" && (
        <Section title="Pojazdy ciężarowe" cars={cars.specjalne} contactBelow />
      )}
      <Contact />
    </Layout>
  )
}

export default Flota
